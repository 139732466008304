import analytics from '@capturi/analytics'
import { usePageTitle } from '@capturi/react-utils'
import { useOrganization } from '@capturi/stores'
import { PageHeading } from '@capturi/ui-components'
import {
  Box,
  Heading,
  List,
  ListItem,
  Spinner,
  Text,
  VStack,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import routes from './routes'
import { useAllMetabaseReports } from './useMetabaseReports'

const MetabaseReportList: React.FC = () => {
  const { uid: organizationUid } = useOrganization()
  usePageTitle('Reports')

  const { data: metabaseReports, isLoading } =
    useAllMetabaseReports(organizationUid)

  const navigate = useNavigate()
  return (
    <>
      <PageHeading mb={10}>
        <Trans>Reports</Trans>
      </PageHeading>
      <Box textAlign="center">
        {isLoading && <Spinner />}

        <List
          borderRadius="base"
          border="1px solid"
          borderColor="gray.200"
          boxShadow="md"
          overflow="hidden"
        >
          {metabaseReports?.reports.map((t) => (
            <ListItem
              onClick={() => {
                navigate(routes.report(t.uid))
                analytics.event('Metabase_Report_opened', {
                  t,
                })
              }}
              minH="16"
              cursor="pointer"
              alignItems="center"
              display="flex"
              py={4}
              px={8}
              key={t.title}
              borderBottom="1px solid"
              borderColor="gray.300"
              background="gray.50"
              css={css`
                :last-child {
                  border-bottom: none;
                }
              `}
            >
              <VStack alignItems="flex-start" gap="0">
                <Heading fontSize="xl">{t.title}</Heading>
                <Text color="gray.600">{t.description}</Text>
              </VStack>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  )
}

export default MetabaseReportList
